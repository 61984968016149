import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import { NotificationBadgeProvider } from './contexts/NotificationBadgeContext';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./modules/Pages/Login/Login'));
const MerchantLogin = React.lazy(() => import('./modules/Pages/Login/merchantLogin'));
const OutletLogin = React.lazy(() => import('./modules/Pages/Login/outletLogin'));
const CustomerLogin = React.lazy(() => import('./modules/Pages/Login/customerLogin'));
const ResetPassword = React.lazy(() => import('./modules/Pages/Login/resetPassword'));
const ResetMerchant = React.lazy(() => import('./modules/Pages/Login/resetMerchant'));
const ResetOutlet = React.lazy(() => import('./modules/Pages/Login/resetOutlet'));
const ResetCustomer = React.lazy(() => import('./modules/Pages/Login/resetCustomer'));
const Register = React.lazy(() => import('./modules/Pages/Register/Register'));
const Page404 = React.lazy(() => import('./modules/Pages/Page404/Page404'));
const Page500 = React.lazy(() => import('./modules/Pages/Page500/Page500'));

const App = () => {
  return (
    <BrowserRouter>
      <NotificationBadgeProvider>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route
              exact
              path="/login/reset-password/:code"
              name="Login Page"
              render={props => <ResetPassword {...props} />}
            />
            <Route
              exact
              path="/auth"
              name="Merchant Login Page"
              render={props => <MerchantLogin {...props} />}
            />
            <Route
              exact
              path="/oauth"
              name="Outlet Login Page"
              render={props => <OutletLogin {...props} />}
            />
            <Route
              exact
              path="/user"
              name="Customer Login Page"
              render={props => <CustomerLogin {...props} />}
            />
            <Route
              exact
              path="/auth/reset-password/:code"
              name="Merchant Reset Page"
              render={props => <ResetMerchant {...props} />}
            />
            <Route
              exact
              path="/oauth/reset-password/:code"
              name="Outlet Reset Page"
              render={props => <ResetOutlet {...props} />}
            />
            <Route
              exact
              path="/user/reset-password/:code"
              name="Customer Reset Page"
              render={props => <ResetCustomer {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={props => <Register {...props} />}
            />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </NotificationBadgeProvider>
    </BrowserRouter>
  );
};

export default App;
