import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useAsync from '@alifaishol/use-async';
import axios from 'axios';

const getNotificationCount = async () => {
  const token = localStorage.getItem('accessToken');
  const responses = await Promise.all([
    axios.get(`${process.env.REACT_APP_API_URI}/admin/customer/notif-menu/1`, {
      headers: { token, key: 'M1daz20-S3cret19' },
    }),
    axios.get(`${process.env.REACT_APP_API_URI}/admin/customer/notif-menu/2`, {
      headers: { token, key: 'M1daz20-S3cret19' },
    }),
    axios.get(`${process.env.REACT_APP_API_URI}/admin/customer/notif-menu/3`, {
      headers: { token, key: 'M1daz20-S3cret19' },
    }),
    axios.get(`${process.env.REACT_APP_API_URI}/admin/customer/notif-menu/4`, {
      headers: { token, key: 'M1daz20-S3cret19' },
    }),
    axios.get(`${process.env.REACT_APP_API_URI}/admin/customer/notif-menu/5`, {
      headers: { token, key: 'M1daz20-S3cret19' },
    }),
  ]);

  return {
    'Top Up': responses[0].data?.data ?? 0,
    'Upgrade Premium': responses[1].data?.data ?? 0,
    'Penutupan Akun': responses[2].data?.data ?? 0,
    'Unblock Akun': responses[3].data?.data ?? 0,
    'Unfreeze Akun': responses[4].data?.data ?? 0,
  };
};

const NotificationBadgeContext = React.createContext();

export const NotificationBadgeProvider = ({ children }) => {
  const {
    exec,
    state: { data },
  } = useAsync(getNotificationCount);

  useEffect(() => {
    exec();
  }, [exec]);

  return (
    <NotificationBadgeContext.Provider value={{ data, refresh: exec }}>
      {children}
    </NotificationBadgeContext.Provider>
  );
};

NotificationBadgeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationBadgeContext;
